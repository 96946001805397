<template>
  <section class="h-auto bg-black">
    <div class="px-8 py-14 lg:py-24">
      <div class="flex flex-row items-center justify-center">
        <img
          v-motion-pop-visible
          src="../assets/prev-lead-title.png"
          class="max-h-[100px] lg:max-h-[120px] xl:max-h-[150px] h-full w-auto"
          alt="prev-leaderboard"
        />
      </div>
      <div
        v-if="prevloading == true"
        class="mt-10 text-2xl font-bold text-center text-white md:text-4xl"
        style="font-family: Russo One, sans-serif"
      >
        Waiting for Reset...
      </div>
      <div
        v-if="prevloading == true"
        class="mt-10 text-2xl font-bold text-center text-white md:text-4xl"
        style="font-family: Russo One, sans-serif"
      >
        Loading Previous Leaderboard...
      </div>
      <div v-if="prevloading == false" class="flex items-center justify-center">

          <!-- Toggle Switch for Desktop -->
          <!-- Original version with Clash.GG commented out -->
<div class="block mt-6">
  <div
    class="relative flex items-center w-[330px] md:w-[530px] h-[50px] rounded-full bg-[#440101]"
  >
    <!-- Slider Background -->
    <div
      class="absolute top-0 left-0 w-1/2 h-full rounded-full bg-gradient-to-r from-[#050505] via-[#5C2027] to-[#8B1259] transition-all duration-300"
      :class="{
        'translate-x-0': selectedPrevLeaderboard === 'roobet',
        'translate-x-[112px] md:translate-x-[264px]': selectedPrevLeaderboard === 'raingg',
        // 'translate-x-[220px] md:translate-x-[352px]': selectedPrevLeaderboard === 'clashgg',
      }"
    ></div>

    <!-- Roobet Button -->
    <button
      class="absolute left-0 w-1/2 h-full flex items-center justify-center text-white text-[8px] md:text-[14px] font-bold transition-all duration-300"
      @click="selectedPrevLeaderboard = 'roobet'"
      style="font-family: Stalinist One, sans-serif"
    >
      ROOBET
    </button>

    <!-- Rain.GG Button -->
    <button
      class="absolute left-1/2 w-1/2 h-full flex items-center justify-center text-white text-[8px] md:text-[14px] font-bold transition-all duration-300"
      @click="selectedPrevLeaderboard = 'raingg'"
      style="font-family: Stalinist One, sans-serif"
    >
      RAIN.GG
    </button>

    <!-- Clash.GG Button (Commented out) -->
    <!--
    <button
      class="absolute left-2/3 w-1/3 h-full flex items-center justify-center text-white text-[8px] md:text-[14px] font-bold transition-all duration-300"
      @click="selectedPrevLeaderboard = 'clashgg'"
      style="font-family: Stalinist One, sans-serif"
    >
      CLASH.GG
    </button>
    -->
  </div>
</div>
      </div>
      <div v-if="prevloading == false" class="hidden md:block">
        <div
          class="flex justify-center mt-8 mb:mb-24"
          style="font-family: Russo One, sans-serif"
        >
          <table class="w-full border-separate lg:w-auto border-spacing-y-6">
            <thead
              v-motion-slide-visible-bottom
              class="rounded-2xl"
              :class="
                selectedPrevLeaderboard == 'raingg'
                  ? 'bg-gradient-to-r from-[#9B005D] via-[#350003] to-[#57003F]'
                  : 'bg-gradient-to-r from-[#840000] via-[#350003] to-[#57003F]'
              "
            >
              <tr>
                <th
                  class="px-1 md:px-[45px] lg:pr-24 lg:pl-8 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-left rounded-l-full"
                >
                  Ranks
                </th>
                <th
                  class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-center"
                >
                  User
                </th>
                <th
                  class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-center"
                >
                  Amount
                </th>
                <th
                  class="px-1 md:px-[45px] lg:pl-24 lg:pr-8 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-right rounded-r-full"
                >
                  Prize
                </th>
              </tr>
            </thead>
            <tbody class="mt-4">
              <tr
                v-motion-slide-visible-bottom
                v-for="(item, index) in displayedPrevLead"
                :key="index"
                :class="
                  selectedPrevLeaderboard == 'raingg'
                    ? 'bg-gradient-to-r from-[#9B005D] via-[#350003] to-[#57003F]'
                    : 'bg-gradient-to-r from-[#840000] via-[#350003] to-[#57003F]'
                "
              >
                <td
                  class="px-1 md:px-[45px] lg:px-10 py-2 md:py-4 whitespace-nowrap text-[18px] font-semibold text-left"
                >
                  <div class="flex flex-row items-center gap-2">
                    <div class="text-white">
                      {{ getOrdinalSuffix(index + 1) }}
                    </div>
                  </div>
                </td>
                <td
                  class="px-1 md:px-[45px] lg:px-24 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-center"
                >
                {{
                        maskString(
                          selectedLeaderboard === "clashgg" ? item.name : item.username
                        )
                      }}
                </td>
                <td
                  class="px-1 md:px-[45px] lg:px-24 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-center"
                >
                  {{
                    selectedPrevLeaderboard === "roobet"
                          ? `$${item?.weightedWagered.toLocaleString()}`
                          : selectedPrevLeaderboard === "raingg"
                          ? `${item?.wagered.toLocaleString()}c`
                          : `${item?.wagered.toLocaleString()} gems`
                  }}
                </td>
                <td
                  class="px-1 md:px-[45px] lg:px-8 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-right"
                  v-html="
                        selectedPrevLeaderboard === 'roobet'
                          ? getRoobetRewardValue(index)
                          : selectedPrevLeaderboard === 'raingg'
                          ? getRainggRewardValue(index)
                          : getClashggRewardValue(index)
                      "
                >
                  </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="prevloading == false" class="block md:hidden">
        <div
          class="flex justify-center mt-8 text-xl mb:mb-24"
          style="font-family: Russo One, sans-serif"
        >
          <table class="w-full lg:w-auto">
            <thead v-motion-slide-visible-bottom>
              <tr>
                <th
                  class="md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-left"
                >
                  Ranks
                </th>
                <th
                  class="md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-center"
                >
                  User
                </th>
                <th
                  class="md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-center"
                >
                  Amount
                </th>
                <th
                  class="md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-right"
                >
                  Prize
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-motion-slide-visible-bottom
                v-for="(item, index) in displayedPrevLead"
                :key="index"
              >
                <td
                  class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-left"
                >
                  {{ getOrdinalSuffix(index + 1) }}
                </td>
                <td
                  class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-center"
                >
                {{
                        maskString(
                          selectedLeaderboard === "clashgg" ? item.name : item.username
                        )
                      }}
                </td>
                <td
                  class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-center"
                >
                {{
                        selectedPrevLeaderboard === "roobet"
                          ? `$${item?.weightedWagered.toLocaleString()}`
                          : selectedPrevLeaderboard === "raingg"
                          ? `${item?.wagered.toLocaleString()}c`
                          : `${item?.wagered.toLocaleString()} gems`
                      }}
                </td>
                <td
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-right"
                      v-html="
                        selectedPrevLeaderboard === 'roobet'
                          ? getRoobetRewardValue(index)
                          : selectedPrevLeaderboard === 'raingg'
                          ? getRainggRewardValue(index)
                          : getClashggRewardValue(index)
                      "
                    ></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
// import { RAINGG_PRIZES_PREVIOUS, ROOBET_PRIZES_PREVIOUS } from "../../const";

export default {
  data() {
    return {
      displayedPrevLead: [],
      roobetPrevLeaderboard: [],
      rainggPrevLeaderboard: [],
      clashggPrevLeaderboard: [],
      prevloading: false,

      selectedPrevLeaderboard: "roobet",
    };
  },
  methods: {
    async initPrevLeaderboard() {
      try {
        const baseUrl = process.env.VUE_APP_BASE_API;
        this.prevLoading = true;

        // Fetch Roobet previous leaderboard
        const roobetRes = await axios.get(
          `${baseUrl}/api/leaderboard/roobet?previous=true`,
          {
            headers: { "x-api-key": process.env.VUE_APP_X_API_KEY },
          }
        );
        this.roobetPrevLeaderboard = roobetRes.data.leaderboard;

        // Fetch ChickenGG previous leaderboard
        const rainGGRes = await axios.get(
          `${baseUrl}/api/leaderboard/raingg?previous=true`,
          {
            headers: { "x-api-key": process.env.VUE_APP_X_API_KEY },
          }
        );
        this.rainggPrevLeaderboard = rainGGRes.data.leaderboard;

        const clashGGRes = await axios.get(
          `${baseUrl}/api/leaderboard/clashgg?previous=true`,
          {
            headers: { "x-api-key": process.env.VUE_APP_X_API_KEY },
          }
        );
        this.rainggPrevLeaderboard = clashGGRes.data.leaderboard;


        // Set default leaderboard
      } catch (error) {
        console.error("Error loading previous leaderboards:", error);
      } finally {
        this.prevLoading = false;
        this.changePrevLead();
      }
    },
    changePrevLead() {
  const prevLeaderboard =
    this.selectedPrevLeaderboard === "roobet"
      ? this.roobetPrevLeaderboard
      : this.selectedPrevLeaderboard === "raingg"
      ? this.rainggPrevLeaderboard
      : this.clashggPrevLeaderboard; // Added ClashGG

  this.displayedPrevLead = prevLeaderboard.slice(0, 8);
},
    getOrdinalSuffix(n) {
      const s = ["th", "st", "nd", "rd"],
        v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
    getRoobetRewardValue(rank) {
      const currentPrizes = JSON.parse(
        process.env.VUE_APP_ROOBET_PRIZES_PREVIOUS || "[]"
      );

      if (rank >= currentPrizes.length) {
        return "$0";
      }
      return `$${currentPrizes[rank]}`;
    },

    getRainggRewardValue(rank) {
      const currentPrizes = JSON.parse(
        process.env.VUE_APP_RAINGG_PRIZES_PREVIOUS || "[]"
      );

      if (rank >= currentPrizes.length) {
        return "0c";
      }
      return `${currentPrizes[rank]}c`;
    },

    getClashggRewardValue(rank) {
      const clashGGIcon = require("../assets/clashgg-icon.webp");

      const currentPrizes = JSON.parse(
        process.env.VUE_APP_CLASHGG_PRIZES_CURRENT || "[]"
      );

      const prizeValue = rank >= currentPrizes.length ? 0 : currentPrizes[rank];

      return `<span style="display: inline-flex; align-items: center; gap: 4px; color: white;">
            <img src="${clashGGIcon}" alt="Gems Icon" style="width: 16px; height: 16px; vertical-align: middle;">
            ${prizeValue}
          </span>`;
    },

    maskString(str) {
      if (!str || str.length < 2) {
        return str;
      }
      if (!str || str.length <= 4) {
        const start = str.slice(0, 1);
        const end = str.slice(-1);
        const middleAsterisks = "*".repeat(str.length - 2);

        return `${start}${middleAsterisks}${end}`;
      } else {
        const start = str.slice(0, 2);
        const end = str.slice(-2);
        const middleAsterisks = "*".repeat(str.length - 4);

        return `${start}${middleAsterisks}${end}`;
      }
    },
  },
  watch: {
    selectedPrevLeaderboard() {
      this.changePrevLead(); // Automatically update displayed leaderboard
    },
  },
  mounted() {
    this.initPrevLeaderboard();
  },
};
</script>

<style scoped>
.toggle-switch {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 220px;
  height: 56px;
  background: linear-gradient(to right, #140000, #440000 34%);
  border-radius: 28px;
  padding: 0 8px;
  cursor: pointer;
}

.toggle-switch input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch .slider {
  position: absolute;
  left: 10px;
  height: 48px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 24px;
  transition: transform 0.3s ease;
}

.toggle-switch input:checked + .slider {
  /* transform: translateX(180px); */
}

.toggle-switch span {
  color: white;
  font-size: 15px;
  font-weight: bold;
  z-index: 1;
  padding: 0 8px;
}

.toggle-switch .left-label {
  position: absolute;
  left: 40px;
}

.toggle-switch .right-label {
  position: absolute;
  right: 23px;
}
</style>
