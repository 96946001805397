<template>
  <div>
    <!-- Leaderboard Section -->
    <section class="h-auto leaderboard-bg">
      <div class="flex justify-center px-0 lg:pt-[6rem]">
        <div
          class="flex flex-col items-center justify-center p-8 px-0 pt-0 mt-8 lg:mt-0 mb-14 lg:mb-16 rounded-3xl"
          id="leaderboard"
        >
          <div
            v-show="selectedLeaderboard == 'roobet'"
            class="flex flex-row items-center justify-center"
          >
            <img
              v-motion-pop-visible
              src="../assets/leaderboard-title.png"
              class="max-h-[100px] lg:max-h-[120px] xl:max-h-[150px] h-full w-auto"
              alt="leaderboard"
            />
            <a href="https://roobet.com/?ref=romieinc" class="self-end" target="_blank"
              ><img
                v-motion-pop-visible
                src="../assets/RomieIncXRoobetLogo.png"
                class="hidden md:block md:max-w-[350px] xl:max-w-[400px] w-full h-auto"
                alt="logo"
            /></a>
            <!-- <div class="mt-4 font-light text-center text-white" style="font-family: Russo One, sans-serif;">{{ timer }}</div> -->
          </div>

          <!-- RAIN GG -->
          <div
            v-show="selectedLeaderboard == 'raingg'"
            class="flex flex-row items-center justify-center mt-10"
          >
            <a href="https://rain.gg/r/Romieinc" class="self-start" target="_blank"
              ><img
                v-motion-pop-visible
                src="../assets/RomieIncXRainggLogo.png"
                class="hidden md:block max-w-[300px] md:max-w-[350px] xl:max-w-[410px] w-full h-auto"
                alt="raingg-logo"
            /></a>
            <img
              v-motion-pop-visible
              src="../assets/raingg-leaderboard-title.png"
              class="px-5 pt-5 max-h-[100px] lg:max-h-[120px] xl:max-h-[170px] h-full w-auto"
              alt="raingg leaderboard"
            />
          </div>

          <!-- CLASH GG -->
          <div
            v-show="selectedLeaderboard == 'clashgg'"
            class="flex flex-row items-center justify-center mt-10"
          >
            <img
              v-motion-pop-visible
              src="../assets/clashgg-leaderboard-title.png"
              class="px-5 pt-5 max-h-[100px] lg:max-h-[120px] xl:max-h-[170px] h-full w-auto"
              alt="raingg leaderboard"
            />
            <a href="https://clash.gg/r/ROMIEINC" class="self-start" target="_blank"
              ><img
                v-motion-pop-visible
                src="../assets/RomieIncXClashggLogo.png"
                class="hidden md:block max-w-[300px] md:max-w-[350px] xl:max-w-[410px] w-full h-auto"
                alt="raingg-logo"
            /></a>
          </div>
          <div
            v-motion-slide-visible-bottom
            class="text-center text-white font-medium px-8 py-2 my-4 text-[15px]"
            :class="{
              'bg-gradient-to-r from-[#1B0202] via-[#330000_50%] to-[#1B0202] mt-4':
                selectedLeaderboard === 'roobet',
              'bg-gradient-to-r from-[#1B0202] via-[#330000_50%] to-[#1B0202]':
                selectedLeaderboard === 'raingg' || selectedLeaderboard === 'clashgg',
            }"
            style="font-family: Russo One, sans-serif"
          >
            {{
              selectedLeaderboard === "roobet"
                ? roobetTimer
                : selectedLeaderboard === "raingg"
                ? rainGGTimer
                : clashGGTimer
            }}
            {{ loading ? "Calculating time" : "" }}
          </div>

          <!-- Toggle Switch for Desktop -->
          <div class="block mt-6">
            <div
              class="relative flex items-center w-[330px] md:w-[530px] h-[50px] rounded-full bg-[#440101]"
            >
              <!-- Slider Background -->
              <div
                class="absolute top-0 left-0 w-1/3 h-full rounded-full bg-gradient-to-r from-[#050505] via-[#5C2027] to-[#8B1259] transition-all duration-300"
                :class="{
                  'translate-x-0': selectedLeaderboard === 'roobet',
                  'translate-x-[112px] md:translate-x-[172px]': selectedLeaderboard === 'raingg',
                  'translate-x-[220px] md:translate-x-[352px]': selectedLeaderboard === 'clashgg',
                }"
              ></div>

              <!-- Roobet Button -->
              <button
                class="absolute left-0 w-1/3 h-full flex items-center justify-center text-white text-[8px] md:text-[14px] font-bold transition-all duration-300"
                @click="selectedLeaderboard = 'roobet'"
                style="font-family: Stalinist One, sans-serif"
              >
                ROOBET
              </button>

              <!-- Rain.GG Button -->
              <button
                class="absolute left-1/3 w-1/3 h-full flex items-center justify-center text-white text-[8px] md:text-[14px] font-bold transition-all duration-300"
                @click="selectedLeaderboard = 'raingg'"
                style="font-family: Stalinist One, sans-serif"
              >
                RAIN.GG
              </button>

              <!-- Clash.GG Button -->
              <button
                class="absolute left-2/3 w-1/3 h-full flex items-center justify-center text-white text-[8px] md:text-[14px] font-bold transition-all duration-300"
                @click="selectedLeaderboard = 'clashgg'"
                style="font-family: Stalinist One, sans-serif"
              >
                CLASH.GG
              </button>
            </div>
          </div>

          <div v-if="loading == false" class="hidden md:block">
            <div
              class="flex justify-center mt-8 mb:mb-24"
              style="font-family: Russo One, sans-serif"
            >
              <table class="w-full border-separate lg:w-auto border-spacing-y-6">
                <thead
                  v-motion-slide-visible-bottom
                  class="rounded-2xl font-['Stalinist_One']"
                  :class="selectedLeaderboard == 'raingg' ? 'bg-black' : 'bg-black'"
                >
                  <tr>
                    <th
                      class="px-1 md:px-6 lg:pr-20 lg:pl-8 py-2 md:py-4 text-transparent text-lg xl:text-[25px] font-normal xl:font-semibold text-left rounded-l-full bg-clip-text bg-gradient-to-r from-[#FF0000] via-[#FF7373_50%] to-[#FF0000]"
                    >
                      Ranks
                    </th>
                    <th
                      class="px-1 md:px-10 lg:px-16 py-2 md:py-4 text-transparent text-lg xl:text-[25px] font-normal xl:font-semibold text-center bg-clip-text bg-gradient-to-r from-[#FF0000] via-[#FF7373_50%] to-[#FF0000]"
                    >
                      User
                    </th>
                    <th
                      class="px-1 md:px-10 lg:px-16 py-2 md:py-4 text-transparent text-lg xl:text-[25px] font-normal xl:font-semibold text-center bg-clip-text bg-gradient-to-r from-[#FF0000] via-[#FF7373_50%] to-[#FF0000]"
                    >
                      Amount
                    </th>
                    <th
                      class="px-1 md:px-8 lg:pl-16 lg:pr-8 py-2 md:py-4 text-transparent text-lg xl:text-[25px] font-normal xl:font-semibold text-right rounded-r-full bg-clip-text bg-gradient-to-r from-[#FF0000] via-[#FF7373_50%] to-[#FF0000]"
                    >
                      Prize
                    </th>
                  </tr>
                </thead>
                <tbody class="mt-4">
                  <tr
                    v-motion-slide-visible-bottom
                    v-for="(item, index) in top3"
                    :key="index"
                    :class="
                      selectedLeaderboard == 'raingg'
                        ? 'bg-gradient-to-r from-[#9B005D] via-[#350003] to-[#57003F]'
                        : 'bg-gradient-to-r from-[#840000] via-[#350003] to-[#57003F]'
                    "
                  >
                    <td
                      class="px-1 md:px-[45px] lg:px-10 py-2 md:py-4 whitespace-nowrap text-[18px] font-semibold text-left"
                    >
                      <div class="flex flex-row items-center gap-2">
                        <div class="text-white">
                          {{ getOrdinalSuffix(index + 1) }}
                        </div>
                        <!-- <img src="../assets/logo.png" class="w-[40px]" alt="logo"> -->
                      </div>
                    </td>
                    <td
                      class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 whitespace-nowrap text-[18px] text-white font-thin text-center"
                    >
                      {{
                        maskString(
                          selectedLeaderboard === "clashgg" ? item.name : item.username
                        )
                      }}
                    </td>
                    <td
                      class="flex justify-center items-center px-1 md:px-[45px] lg:px-24 py-2 md:py-4 whitespace-nowrap text-[18px] text-white font-thin text-center"
                    >
                    <img v-if="selectedLeaderboard === 'clashgg'" src="../assets/clashgg-icon.webp" alt="ClashGG Icon" style="width: 16px; height: 16px; margin-right: 4px;">
                      {{
                        selectedLeaderboard === "roobet"
                          ? `$${item?.weightedWagered?.toLocaleString()}`
                          : selectedLeaderboard === "raingg"
                          ? `${item?.wagered?.toLocaleString()}c`
                          : `${item?.wagered?.toLocaleString()}`
                      }}
                   
                    </td>
                    <td
                      class="px-1 md:px-[45px] lg:px-8 py-2 md:py-4 whitespace-nowrap text-[18px] text-white font-thin text-right"
                      v-html="
                        selectedLeaderboard === 'roobet'
                          ? getRoobetRewardValue(index)
                          : selectedLeaderboard === 'raingg'
                          ? getRainggRewardValue(index)
                          : getClashggRewardValue(index)
                      "
                    ></td>
                  </tr>
                </tbody>
                <tbody class="mt-8">
                  <tr
                    v-motion-slide-visible-bottom
                    v-for="(item, index) in topRest"
                    :key="index"
                    class="bg-transparent"
                  >
                    <td
                      class="px-1 md:px-[45px] lg:px-10 py-2 md:py-4 whitespace-nowrap text-[18px] font-semibold text-left"
                    >
                      <div class="flex flex-row items-center gap-2">
                        <div class="text-white">
                          {{ getOrdinalSuffix(index + 4) }}
                        </div>
                        <!--   <img src="../assets/logo.png" class="w-[40px]" alt="logo"> -->
                      </div>
                    </td>
                    <td
                      class="px-1 md:px-[45px] lg:px-24 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-center"
                    >
                      {{
                        maskString(
                          selectedLeaderboard === "clashgg" ? item.name : item.username
                        )
                      }}
                    </td>
                    <td
                      class="flex justify-center items-center px-1 md:px-[45px] lg:px-24 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-center"
                    >
                    <img v-if="selectedLeaderboard === 'clashgg'" src="../assets/clashgg-icon.webp" alt="ClashGG Icon" style="width: 16px; height: 16px; margin-right: 4px;">
                      {{
                        selectedLeaderboard === "roobet"
                          ? `$${item?.weightedWagered?.toLocaleString()}`
                          : selectedLeaderboard === "raingg"
                          ? `${item?.wagered?.toLocaleString()}c`
                          : `${item?.wagered?.toLocaleString()}`
                      }}
                    </td>
                    <td
                      class="px-1 md:px-[45px] lg:px-8 py-2 md:py-4 whitespace-nowrap text-[18px] text-white font-thin text-right"
                      v-html="
                        selectedLeaderboard === 'roobet'
                          ? getRoobetRewardValue(index + 3)
                          : selectedLeaderboard === 'raingg'
                          ? getRainggRewardValue(index + 3)
                          : getClashggRewardValue(index + 3)
                      "
                    ></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="loading == false" class="block md:hidden">
            <div
              class="flex justify-center mt-8 text-xl mb:mb-24"
              style="font-family: Russo One, sans-serif"
            >
              <table class="w-full lg:w-auto">
                <thead v-motion-slide-visible-bottom>
                  <tr>
                    <th
                      class="md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-left"
                    >
                      Ranks
                    </th>
                    <th
                      class="md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-center"
                    >
                      User
                    </th>
                    <th
                      class="md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-center"
                    >
                      Amount
                    </th>
                    <th
                      class="md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-right"
                    >
                      Prize
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-motion-slide-visible-bottom
                    v-for="(item, index) in topForMobile"
                    :key="index"
                  >
                    <td
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-left"
                    >
                      {{ getOrdinalSuffix(index + 1) }}
                    </td>
                    <td
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-center"
                    >
                      {{
                        maskString(
                          selectedLeaderboard === "clashgg" ? item.name : item.username
                        )
                      }}
                    </td>
                    <td
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-center"
                    >
                      {{
                        selectedLeaderboard === "roobet"
                          ? `$${item?.weightedWagered.toLocaleString()}`
                          : selectedLeaderboard === "raingg"
                          ? `${item?.wagered.toLocaleString()}c`
                          : `${item?.wagered.toLocaleString()} gems`
                      }}
                    </td>
                    <td
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-right"
                      v-html="
                        selectedLeaderboard === 'roobet'
                          ? getRoobetRewardValue(index)
                          : selectedLeaderboard === 'raingg'
                          ? getRainggRewardValue(index)
                          : getClashggRewardValue(index)
                      "
                    ></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            v-if="loading == false && selectedLeaderboard == 'roobet'"
            v-motion-pop-visible
            class="flex justify-center text-white mt-8 lg:mt-14 uppercase font-semibold tracking-wider px-2 md:px-4 lg:px-48 2xl:px-96 2xl:ml-4 bg-gradient-to-r from-[#020202] via-[#200000_50%] to-[#020202] msm:m-[20px] msm:p-[1rem]"
            style="font-family: Roboto, serif"
          >

            Your wagers on Roobet will count towards the leaderboard at the
            following weights based on the games you are playing. This helps
            prevent leaderboard abuse:

            <br /><br />*Games with an RTP under 96% will contribute 100% of the
            amount wagered to the leaderboard. <br />*Games with an RTP of 97%
            will contribute 50% of the amount wagered to the leaderboard.
            <br />*Games with an RTP of 98- 99% and above will contribute 10% of
            the amount wagered to the leaderboard. <br />*Only house games and
            slots(dice excluded) count towards leaderboard.

          </div>
          <!-- <div
            v-if="loading == false && selectedLeaderboard"
            v-motion-pop-visible
            class="flex justify-center px-2 mt-4 text-xl tracking-wider text-center text-white uppercase shadow-xl lg:mt-5 font-base"
            style="font-family: Roboto, serif"
          >
            *All wagers for Chicken.GG from 11/20/2024 will be
            counted until Chicken.GG Leaderboard is integrated
          </div> -->
        </div>
      </div>
    </section>
    <PrevLeaderBoardSection ref="PrevLeaderBoardSection" />
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment-timezone";
import PrevLeaderBoardSection from "./PrevLeaderBoardSection.vue";
// import { RAINGG_PRIZES_CURRENT, ROOBET_PRIZES_CURRENT } from "../../const";
const clashGGIcon = require("../assets/clashgg-icon.webp");

export default {
  components: {
    PrevLeaderBoardSection,
  },
  data() {
    return {
      top3: [],
      topRest: [],
      topForMobile: [],

      roobetTimer: "",
      rainGGTimer: "",
      clashGGTimer: "",
      // packdrawTimer: "",
      // chickenggTimer: "",

      rooberLeaderboard: [],
      rainGGLeaderboard: [],
      clashGGLeaderboard: [],
      // packdrawLeaderboard: [],
      // chickenggLeaderboard: [],

      selectedLeaderboard: "roobet",

      roobetStartDate: null,
      roobetEndDate: null,

      rainGGStartDate: null,
      rainGGEndDate: null,

      clashGGStartDate: null,
      clashGGEndDate: null,

      // packdrawStartDate: null,
      // packdrawEndDate: null,

      roobetNotFormattedEndDate: null,
      rainGGNotFormattedEndDate: null,
      clashGGNotFormattedEndDate: null,
      // packdrawNotFormattedEndDate: null,
      // chickenggNotFormattedEndDate: null,

      loading: false,
    };
  },
  methods: {
    async init() {
      try {
        const baseUrl = process.env.VUE_APP_BASE_API;
        this.loading = true;

        // Fetch Roobet leaderboard
        const roobetRes = await axios.get(`${baseUrl}/api/leaderboard/roobet`, {
          headers: { "x-api-key": process.env.VUE_APP_X_API_KEY },
        });
        this.roobetLeaderboard = roobetRes.data.leaderboard;
        this.roobetNotFormattedEndDate = roobetRes.data.endDate;

        // Fetch RainGG leaderboard
        const rainGGRes = await axios.get(`${baseUrl}/api/leaderboard/raingg`, {
          headers: { "x-api-key": process.env.VUE_APP_X_API_KEY },
        });
        this.rainGGLeaderboard = rainGGRes.data.leaderboard;
        this.rainGGNotFormattedEndDate = rainGGRes.data.endDate;

        // Fetch ClashGG leaderboard
        const clashGGRes = await axios.get(`${baseUrl}/api/leaderboard/clashgg`, {
          headers: { "x-api-key": process.env.VUE_APP_X_API_KEY },
        });
        this.clashGGLeaderboard = clashGGRes.data.leaderboard;
        this.clashGGNotFormattedEndDate = clashGGRes.data.endDate;
      } catch (error) {
        console.error("Error loading leaderboards:", error);
      } finally {
        this.loading = false;
        // Load the default leaderboard
        this.changeLeaderboard();

        // Start timers after setting data
        this.startRoobetTimer();
        this.startRainTimer();
        this.startClashGGTimer();
      }
    },
    changeLeaderboard() {
      let leaderboard;

      if (this.selectedLeaderboard === "roobet") {
        leaderboard = this.roobetLeaderboard;
      } else if (this.selectedLeaderboard === "raingg") {
        leaderboard = this.rainGGLeaderboard;
      } else if (this.selectedLeaderboard === "clashgg") {
        leaderboard = this.clashGGLeaderboard;
      }

      this.top3 = leaderboard.slice(0, 3);
      this.topRest = leaderboard.slice(3, 8);
      this.topForMobile = leaderboard.slice(0, 8);
    },

    getOrdinalSuffix(n) {
      const s = ["th", "st", "nd", "rd"],
        v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
    getRoobetRewardValue(rank) {
      const currentPrizes = JSON.parse(process.env.VUE_APP_ROOBET_PRIZES_CURRENT || "[]");

      if (rank >= currentPrizes.length) {
        return "$0";
      }
      return `$${currentPrizes[rank]}`;
    },

    getRainggRewardValue(rank) {
      const currentPrizes = JSON.parse(process.env.VUE_APP_RAINGG_PRIZES_CURRENT || "[]");

      if (rank >= currentPrizes.length) {
        return "0c";
      }
      return `${currentPrizes[rank]}c`;
    },

    getClashggRewardValue(rank) {

      const currentPrizes = JSON.parse(
        process.env.VUE_APP_CLASHGG_PRIZES_CURRENT || "[]"
      );

      const prizeValue = rank >= currentPrizes.length ? 0 : currentPrizes[rank];

      return `<span style="display: inline-flex; align-items: center; gap: 4px; color: white;">
            <img src="${clashGGIcon}" alt="Gems Icon" style="width: 16px; height: 16px; vertical-align: middle;">
            ${prizeValue}
          </span>`;
    },
    startRoobetTimer() {
      const getNextTargetDate = () => {
        // const now = moment.tz("America/New_York");
        const now = moment().utc();
        // let targetDate = moment
        //   .tz(this.roobetNotFormattedEndDate, "America/New_York")
        //   .hour(23)
        //   .minute(59)
        //   .second(59)
        //   .subtract(1, "days");
        let targetDate = moment(this.roobetNotFormattedEndDate).utc();

        if (now.isSameOrAfter(targetDate)) {
          targetDate.add(1, "month");
        }

        return targetDate;
      };

      let targetDate = getNextTargetDate();
      let roobetTimerInterval;

      const updateTimer = () => {
        // const nowEst = moment.tz("America/New_York");
        const nowEst = moment().utc();
        const distance = targetDate.diff(nowEst);

        if (distance < 0) {
          this.timer = "Leaderboard ended";
          this.init();
          this.$refs.PrevLeaderBoardSection.init();
          clearInterval(roobetTimerInterval);

          targetDate = getNextTargetDate();
          this.startRoobetTimer();
        } else {
          const duration = moment.duration(distance);
          const days = Math.floor(duration.asDays());
          const hours = duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();

          this.roobetTimer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        }
      };
      updateTimer();
      roobetTimerInterval = setInterval(updateTimer, 1000);
    },
    startRainTimer() {
      const getNextTargetDate = () => {
        const now = moment().utc();
        let targetDate = moment(this.rainGGNotFormattedEndDate).utc();

        if (now.isSameOrAfter(targetDate)) {
          targetDate.add(1, "month");
        }

        return targetDate;
      };

      let targetDate = getNextTargetDate();
      let rainTimerInterval;

      const updateTimer = () => {
        const now = moment().utc();
        const distance = targetDate.diff(now);

        if (distance < 0) {
          this.rainTimer = "Leaderboard ended";
          this.init();
          this.$refs.PrevLeaderBoardSection.init();
          clearInterval(rainTimerInterval);

          targetDate = getNextTargetDate();
          this.startRainTimer();
        } else {
          const duration = moment.duration(distance);
          const days = Math.floor(duration.asDays());
          const hours = duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();

          this.rainGGTimer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        }
      };
      updateTimer();
      rainTimerInterval = setInterval(updateTimer, 1000);
    },
    startClashGGTimer() {
      const getNextTargetDate = () => {
        const now = moment().utc();
        let targetDate = moment(this.clashGGNotFormattedEndDate).utc();

        if (now.isSameOrAfter(targetDate)) {
          targetDate.add(1, "month");
        }

        return targetDate;
      };

      let targetDate = getNextTargetDate();
      let clashTimerInterval;

      const updateTimer = () => {
        const now = moment().utc();
        const distance = targetDate.diff(now);

        if (distance < 0) {
          this.rainTimer = "Leaderboard ended";
          this.init();
          this.$refs.PrevLeaderBoardSection.init();
          clearInterval(clashTimerInterval);

          targetDate = getNextTargetDate();
          this.startClashGGTimer();
        } else {
          const duration = moment.duration(distance);
          const days = Math.floor(duration.asDays());
          const hours = duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();

          this.clashGGTimer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        }
      };
      updateTimer();
      clashTimerInterval = setInterval(updateTimer, 1000);
    },
    // startChickenggTimer() {
    //   const getNextTargetDate = () => {
    //     // const now = moment.utc();

    //     let targetDate = moment
    //       .utc(this.chickenggNotFormattedEndDate)
    //       .subtract(1, "seconds");

    //     // if (now.isAfter(targetDate)) {
    //     //   targetDate.add(1, 'month');
    //     // }

    //     return targetDate;
    //   };

    //   let targetDate = getNextTargetDate();
    //   let timerInterval;

    //   const updateTimer = () => {
    //     const nowUtc = moment.utc();
    //     const distance = targetDate.diff(nowUtc);

    //     if (distance < 0) {
    //       this.timer = "Leaderboard ended";
    //       // this.init();
    //       clearInterval(timerInterval);

    //       // targetDate = getNextTargetDate();
    //       // this.startTimer();
    //     } else {
    //       const duration = moment.duration(distance);
    //        const days = Math.floor(duration.asDays());
    //        const hours = duration.hours();
    //        const minutes = duration.minutes();
    //        const seconds = duration.seconds();

    //       this.chickenggTimer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
    //     }
    //   };

    //   updateTimer();
    //   timerInterval = setInterval(updateTimer, 1000);
    // },
    maskString(str) {
      if (!str || str.length < 2) {
        return str;
      }
      if (!str || str.length <= 4) {
        const start = str.slice(0, 1);
        const end = str.slice(-1);
        const middleAsterisks = "*".repeat(str.length - 2);

        return `${start}${middleAsterisks}${end}`;
      } else {
        const start = str.slice(0, 2);
        const end = str.slice(-2);
        const middleAsterisks = "*".repeat(str.length - 4);

        return `${start}${middleAsterisks}${end}`;
      }
    },
    formatDateNow() {
      return moment().utc().format("MM/DD/YYYY");
    },
  },
  watch: {
    selectedLeaderboard() {
      this.changeLeaderboard();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.leaderboard-bg {
  background-image: url("../assets/leaderboard-section-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.toggle-switch {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between; /* Ensures even spacing */
  width: 240px;
  height: 56px;
  background: linear-gradient(to right, #140000, #440000 34%);
  border-radius: 28px;
  padding: 0 8px;
  cursor: pointer;
}

.toggle-switch input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch .slider {
  /* position: absolute;
  left: 4px; */
  width: 240px;
  height: 48px;
  /* background-color: rgba(255, 255, 255, 0.4); */
  /* border-radius: 24px; */
  transition: transform 0.3s ease;
}

.toggle-switch input:checked + .slider {
  /* transform: translateX(180px); */
}

.toggle-switch span {
  color: white;
  font-size: 15px;
  font-weight: bold;
  z-index: 1;
  width: 50%; /* Ensures text stays within button bounds */
  text-align: center; /* Centers text */
  position: absolute;
  top: 50%; /* Moves it down */
  transform: translateY(-50%); /* Centers vertically */
}

.toggle-switch .left-label {
  position: absolute;
  top: 16px;
  left: 48px;
}

.toggle-switch .right-label {
  position: absolute;
  top: 18px;
  right: 30px;
}
</style>
